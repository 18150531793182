import React from "react";

import { Link } from "gatsby";

import * as styles from "./FeaturedPosts.module.scss";

type Props = {
    posts: Array<{
      slug: string;
      title: string;
    }>;
  };

const FeaturedPost = ({ posts }: Props) => (
    <div>
    <h4>Featured posts</h4>
    <nav className={styles.menu}>
    <ul className={styles.list}>
      {posts.map((item) => (
        <li className={styles.item} key={item.slug}>
          <Link
            to={"/"+item.slug}
            className={styles.link}
            activeClassName={styles.active}
          >
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
  </div>
    
);

export default FeaturedPost;
